.contactUsSection {
    position: relative;
    padding-top: 1rem;
}

.contactUsSection .card {
    border: none;
    padding: 2rem;
    background: var(--accent_color2);
    border-radius: 0;
    margin-bottom: 10px;
}

.contactUsSection img {
    width: 20% !important;
    margin: 13px;
}

.contactUsSection .card .card-body {
    border: none;
}

.contactUsSection .card .card-title {
    color: var(--primary_color);
    font-size: 1.5em;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 20px;
}

.contactUsSection .card .card-text {
    color: var(--accent_color1);
    font-size: 1em;
    line-height: 32px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media (max-width: 767.98px) {
    .contactUsSection img {
        width: 35% !important;
    }

    .contactUsSection .card .card-title {
        font-size: 24px;
    }

    .contactUsSection .card .card-text {
        font-size: 18px;
    }
}
