.prog-link-wrapper {
    position: relative;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    padding: 1.875em 0;
    margin-bottom: 0;
}

.prog-link-wrapper .prog-link-select {
    display: none;
}

.prog-link-wrapper ul {
    align-items: center;
    list-style: none;
}

.prog-link-wrapper ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
}

.prog-link-wrapper ul li span {
    position: relative;
    color: #046f44;
    font-size: 1.25em;
    font-weight: 400;
    padding-bottom: 0.125em;
}

.prog-link-wrapper ul li span.active::after,
.prog-link-wrapper ul li span:hover::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: var(--primary_color);
}

.prog-link-wrapper .p-dropdown {
    display: none;
}

.prog-link-wrapper .p-dropdown .p-dropdown-trigger {
    color: var(--primary_color);
}

.prog-link-wrapper .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    color: var(--primary_color);
}

.prog-link-wrapper .navbar-nav .nav-item .nav-link:hover {
    background: none;
}

.prog-link-wrapper .dropdown-menu {
    min-width: 214px;
    position: absolute;
    border: none;
    /* background: var(--accent_color2); */
    background: #fff;
    padding: 5px 0;
    top: 100%; /* Align below the parent */
    z-index: 2; /* Bring it above other content */
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.desktop-std-menu1 {
    overflow: visible; /* Ensure the dropdown is not clipped */
}

.prog-link-wrapper .dropdown-menu .dropdown-item {
    color: var(--primary_color);
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 400;
    border-bottom: none;
}

.prog-link-wrapper .dropdown-menu .dropdown-item:hover {
    color: var(--primary_color);
    border-bottom: none;
    background: #f8f8f8;
}

.prog-link-wrapper .dropdown-menu li {
    display: block;
}

.prog-link-wrapper .mobile-std-menu {
    display: none;
}

.prog-link-wrapper .desktop-std-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    overflow: auto;
}

.desktop-std-menu1 {
    position: relative;
    width: 100%;
    overflow-x: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    cursor: grabbing !important;
}

.desktop-std-menu1 span {
    white-space: nowrap !important;
    cursor: pointer !important;
    margin: 0 10px;
    font-size: 1.1em;
    font-weight: 400;
    color: var(--primary_color);
}

@media (max-width: 767.98px) {
    .desktop-std-menu1 {
        display: none;
    }

    .prog-link-wrapper .dropdown-menu {
        position: relative;
    }

    .prog-link-wrapper .navMainWrapper {
        position: relative;
    }

    .prog-link-wrapper .dropdown-menu {
        position: relative;
    }

    .prog-link-wrapper .navMainWrapper .accordion-item {
        position: relative;
        border: none;
        background: 0 0;
    }

    .prog-link-wrapper .navMainWrapper .accordion-item .accordion-header {
        position: relative;
        background: 0 0;
        font-size: 0.8125em;
        font-weight: 500;
    }

    .prog-link-wrapper .navMainWrapper .accordion-item .accordion-header span {
        position: relative;
        background: 0 0;
        box-shadow: none;
        padding: 1em;
        color: #fff;
        font-size: 0.8125em;
        font-weight: 500;
        border-bottom: 1px solid;
        border-radius: 0;
        text-transform: uppercase;
    }

    .prog-link-wrapper .navMainWrapper .accordion-item .accordion-header span::after {
        display: none;
    }

    .prog-link-wrapper .navMainWrapper .accordion-body {
        padding: 0;
        color: #fff;
    }

    .prog-link-wrapper .navMainWrapper .accordion-body ul {
        list-style: none;
        position: relative;
    }

    .prog-link-wrapper .navMainWrapper .accordion-body ul li {
        list-style: none;
        margin: 0;
        padding: 0 0 0 1em;
        text-align: left;
    }

    .prog-link-wrapper .navMainWrapper .arrow-icon {
        font-size: 20px;
    }

    .prog-link-wrapper .mobile-std-menu .nav-item {
        padding: 1em 0;
        display: block;
        border-bottom: 1px solid #d1d1d1;
        width: 100%;
    }

    .prog-link-wrapper .mobile-std-menu .navMainWrapper .accordion-body ul li span {
        font-size: 0.6875em;
        color: #c1c1c1;
        text-transform: uppercase;
    }

    .prog-link-wrapper .mobile-std-menu .navMainWrapper .accordion-body ul li span:hover {
        color: #fff;
    }

    .prog-link-wrapper .mobile-std-menu {
        display: block;
    }

    .prog-link-wrapper .desktop-std-menu {
        display: none;
    }

    .prog-link-wrapper .mobile-std-menu .nav-item {
        padding: 1em 0;
        display: block;
        border-bottom: 1px solid #d1d1d1;
        width: 100%;
    }

    .prog-link-wrapper ul li {
        display: block;
    }

    .prog-link-wrapper .prog-link-select {
        display: block;
        border: none;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0;
        text-transform: uppercase;
        color: #046f44;
    }

    .prog-link-wrapper .p-dropdown {
        display: flex;
    }

    .prog-link-wrapper .p-dropdown .p-dropdown-label {
        padding: 0;
        text-align: left;
        font-weight: 400;
        font-size: 1em;
    }

    .prog-link-wrapper {
        padding: 0.9375em 1.5625em;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0 !important;
    }
}
